export const createUrlParamStr = (obj) => {
  let result = ''
  for (var key in obj) {
    if (result !== '') {
      result += '&'
    }
    result += key + '=' + encodeURIComponent(obj[key])
  }

  return result
}
