import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { createUrlParamStr } from '@utils/createUrlParamStr'

const LinkWithReferrer = ({ href, children, urlParams }) => {
  const router = useRouter()

  return (
    <Link
      href={`${href}?referrer=${router.pathname === '/' ? '/home' : router.pathname || ''}
      ${!urlParams ? '' : `&${createUrlParamStr(urlParams)}`}`}
    >
      {children}
    </Link>
  )
}

LinkWithReferrer.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default LinkWithReferrer
