export const testimonialContent = [
  {
    name: 'Jax Jones',
    location: 'Internationally renowned recording artist & DJ',
    photo: 'jax-jones.jpg',
    quote:
      'Martin being a serial entrepreneur in so many industries, his unique insights empowered me to view my music through a business lens.' +
      '\nNow I’ve found the best model for growth, how to develop my existing brand and public perception to create dense brand loyalty and how to hire the right talent to help me on my journey. So many lessons! Now I’m the best musician I can be but also the best possible business person in tandem. So despite never writing a song in his life, learning from Martin has been nothing short of game changing.',
  },
  {
    name: 'Fred Krom',
    location: 'Tampa, USA',
    photo: 'Fred_Krom.jpg',
    quote:
      'Martin Warner, investment banker turned serial entrepreneur, provides the perfect all-in-one startup seminar covering everything from building the right MVP to validating proof of concept to building a unified and skilled team to fund raising and exit strategies.' +
      '\nEvery bit is as good as Sam Altman’s “How to Start a Startup” class taught at Stanford University. It’s a must for any aspiring startup founder, investor, and/or advisor.',
  },
  {
    name: 'Glen Francis',
    location: 'Bristol, UK',
    photo: 'glen-f.jpg',
    quote:
      "The help I have received so far has been invaluable and exceptional in the same breath. The program to date has met all my expectations especially what you have created for me including the very detailed responses you provide; something you don't get from others I've had in the past. In fact, it's the best program I've come across in many years of seeking the right mentor and program, with no jargon or fluff." +
      '\nI look forward to the continued support.',
  },
  {
    name: 'Bobby Logue',
    location: 'Dundee, Scotland',
    photo: 'bobby-l.png',
    quote:
      "Martin always gives me in-depth information, guidance and some golden nugget tips. I also really enjoy the monthly zoom meetings with Martin; It's a blessing to be involved." +
      '\nMartin’s mentoring along with hard work gave me the confidence that we can achieve anything in life. I am now so close to achieving my dream life, and I will be doing business deals with A-list businessmen like Martin in the future.' +
      '\nThank you again for this opportunity Martin!',
  },
  {
    name: 'Ikenna Ekwueme',
    location: 'London, UK',
    photo: 'ikenna.jpg',
    quote:
      'I encourage everyone to sign up for the Program. The practical knowledge that Martin shares is not easily received from Universities or other business programs.' +
      "\nMartin's mentoring and the Webinars have greatly helped my decision-making and gave me the confidence and boldness that I needed - specifically for my investment and consulting endeavours." +
      '\nThank you very much!',
  },
  {
    name: 'Jack Valentine',
    location: 'Swanley, UK',
    photo: 'jack-v.jpg',
    quote:
      "Martin's personal mentorship has taught me how to lay the right foundations and make the right decisions at the right time for my business." +
      "\nI've more than doubled my revenue since I started the Entrepreneur Seminar program! It has helped me enormously and changed my life.",
  },
  {
    name: 'Sabrina Bell',
    location: 'Peterborough, UK',
    photo: 'sabrina-b.jpg',
    quote:
      'Martin is remarkable, you should definitely join his Program.' +
      '\nThe advice that he gives in the timeframe that he does it in, and the depth and the detail that he goes into to make sure that you have the right answers to avoid any pitfalls and mistakes is incredible. I asked him a question on employing people and he went into some remarkable depth for me which is going to make my recruitment process much more simple.' +
      '\nThank you Martin.',
  },
  {
    name: 'Richard Cardigan',
    location: 'London, UK',
    photo: 'richard-c.jpg',
    quote:
      'Everything I knew about entrepreneurship was turned on its head by simple, clear insights and actions that made me realize what I was doing wrong.' +
      "\nI have applied Martin's strategies and tools to the core of my business, which has enabled me to land 6 figures in extra revenue." +
      '\nThank You Martin.',
  },
  {
    name: 'Morgan Francis',
    location: 'London, UK',
    photo: 'morgan-f.jpg',
    quote:
      'The program has given me the confidence to bet on myself and leap at what I want. I’ve tried setting up a business before but found myself swamped! For example, I was always struggling with managing my time, but Martin provided me with steps to follow, which within a week, made all the difference!' +
      '\nThe great thing about the program is it allows you to work at your own pace, ask Martin specific questions that he answers in GREAT detail. I’m only a couple of months into the program, and I’ve already decided I’ll be signing up for another year!',
  },
]
