import React, { useState } from 'react'
import styled from 'styled-components'
import { usePositionInBrowser } from 'hooks/usePositionInBrowser'
import { useMediaIsMobile, useMediaIsTablet } from 'theme/useMediaIs'
import { CourseButton } from '@components/input/CourseButton'
import { PageContent, View } from '@components/layout'
import SlickSlider from '@components/layout/SlickSlider/SlickSlider'
import LinkWithReferrer from '@components/navigation/LinkWithReferrer'
import { H2 } from '@components/typography'
import { ACCELERATOR } from '@containers/PurchaseApi/PurchaseContainer'
import { TestimonialDetailsVariantA } from './TestimonialDetailsVariantA'
import { TestimonialDetailsVariantB } from './TestimonialDetailsVariantB'
import { TestimonialDetailsVariantC } from './TestimonialDetailsVariantC'
import { testimonialContent } from './content'

const defaultSliderSettings = {
  dots: true,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      // breakpoint means 'everything up to..'
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
        arrows: false,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
        arrows: false,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
        arrows: false,
      },
    },
  ],
}

const Container = styled(PageContent)`
  ${(props) => props.fluid && `padding: 0`};
`

const CentreText = styled(View)`
  text-align: center;
`

const SliderContainer = styled(View)`
  margin-bottom: 50px;
  width: 100%;
`

const Slide = styled(View)`
  outline: none;
  align-items: center;
  align-self: center;
  padding: ${(props) => props.listVerticalPadding}px 0;
`

const Heading = styled(H2)`
  text-align: center;
`

const Slider = styled(SlickSlider)`
  ul.slick-dots {
    bottom: -35px;

    button::before {
      font-size: 60px;
      color: ${(props) => props.theme.colors.primary};
    }
  }

  .slick-center {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    transform: scale(1.2);
  }

  .slick-slide {
    transition: all 0.4s ease;
  }
`

const variants = {
  a: TestimonialDetailsVariantA,
  b: TestimonialDetailsVariantB,
  c: TestimonialDetailsVariantC,
}

export const Testimonials = ({
  hideCourseButton,
  fluid,
  heading,
  variant = 'a',
  sliderSettings = defaultSliderSettings,
  listVerticalPadding = 0,
  ...props
}) => {
  const [expanded, setExpanded] = useState(false)
  const { percentOnScreen } = usePositionInBrowser('slider-container')
  const isMobile = useMediaIsMobile()
  const isTablet = useMediaIsTablet()
  const intervalWhenActive = isMobile ? 10000 : isTablet ? 20000 : 30000
  const TestimonialItemComponent = variants[variant]

  return (
    <Container {...props} fluid={fluid} maxWidth={!fluid ? 1000 : undefined}>
      <Heading>{heading}</Heading>
      <CentreText>
        <SliderContainer id="slider-container">
          <Slider {...sliderSettings} interval={percentOnScreen > 0 ? intervalWhenActive : 1000 * 60 * 60}>
            {testimonialContent.map(
              ({ quote, name, location, photo }) =>
                quote && (
                  <Slide listVerticalPadding={listVerticalPadding} key={`${name}_${location}`}>
                    <TestimonialItemComponent
                      expanded={expanded}
                      toggleExpanded={() => setExpanded(!expanded)}
                      quote={quote}
                      name={name}
                      location={location}
                      photo={photo}
                    />
                  </Slide>
                ),
            )}
          </Slider>
        </SliderContainer>
        {!hideCourseButton && (
          <LinkWithReferrer href="/payment/acceleratorProgram">
            <CourseButton slug={ACCELERATOR} />
          </LinkWithReferrer>
        )}
      </CentreText>
    </Container>
  )
}
