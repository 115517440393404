import React from 'react'
import Slider from 'react-slick/lib'
import styled, { useTheme } from 'styled-components'
import { Icon } from '@components/images'
import PropTypes from 'prop-types'
import { View } from '../View/View'

// This global css must be imported in `pages/_app.js` since Next.js 11
// import './slick-theme.css'
// import './slick.css'

const FETCH_DELAY = 3000

class SlickSlider extends React.Component {
  componentDidMount() {
    this.mounted = true
    if (Slider) {
      this.startAutoPlay()
    } else {
      this.delayStart = true
    }
  }

  componentDidUpdate(prevProps) {
    if (this.slider && this.delayStart) {
      this.delayStart = false
      this.timeOut = setTimeout(this.firstTransition, this.props.interval - FETCH_DELAY)
    }

    if (prevProps.interval !== this.props.interval) {
      clearTimeout(this.timeOut)
      this.timeOut = setTimeout(this.firstTransition, this.props.interval - FETCH_DELAY)
    }
  }

  componentWillUnmount() {
    this.mounted = false
    clearTimeout(this.timeOut)
    clearInterval(this.autoPlay)
  }

  firstTransition = () => {
    this.nextItem()
    this.startAutoPlay()
  }

  startAutoPlay() {
    this.autoPlay = setInterval(this.nextItem, this.props.interval)
  }

  nextItem = () => {
    if (this.mounted && this.getItemsArray().length) {
      this.slider.slickNext()
    }
  }

  getItemsArray() {
    return React.Children.toArray(this.props.children)
  }

  render() {
    const { children, className, ...sliderProps } = this.props
    return Slider ? (
      <Slider
        {...sliderProps}
        className={className}
        ref={(el) => (this.slider = el)}
        prevArrow={<PrevArrow />}
        nextArrow={<NextArrow />}
      >
        {children}
      </Slider>
    ) : (
      <div className={className}>{this.getItemsArray().shift() || null}</div>
    )
  }
}

SlickSlider.propTypes = {
  interval: PropTypes.number.isRequired,
  children: PropTypes.array,
}

const ArrowContainer = styled(View)`
  justify-content: center;
  position: absolute;
  top: 50%;
  width: 40px;
  height: 40px;
  padding: 0;
  cursor: pointer;
  border: none;
  outline: none;
`

const NextArrowContainer = styled(ArrowContainer)`
  right: -80px;
`

const PrevArrowContainer = styled(ArrowContainer)`
  left: -80px;
`

const NextArrow = ({ onClick }) => {
  const theme = useTheme()
  return (
    <NextArrowContainer onClick={onClick}>
      <Icon name="chevron-right" size={40} color={theme.colors.gray} />
    </NextArrowContainer>
  )
}
const PrevArrow = ({ onClick }) => {
  const theme = useTheme()
  return (
    <PrevArrowContainer onClick={onClick}>
      <Icon name="chevron-left" size={40} color={theme.colors.gray} />
    </PrevArrowContainer>
  )
}

export default SlickSlider
