import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { View } from '@components/layout'
import { absoluteFill } from '@theme/styles'
import { webImageUrl } from './WebImage'

const PatternImageWrapper = styled(View)`
  ${absoluteFill};
  z-index: -1;
  overflow: hidden;

  ${media.md`
    flex-direction: row;
  `}
`

const Background = styled(View)`
  opacity: ${(props) => props.patternOpacity};
  ${absoluteFill};
  background-image: url(${webImageUrl('common/patterns/shapes.png')});
  background-repeat: repeat;
  background-size: contain;
  transform: ${(props) => `scale(${props.patternScale})`};
  filter: grayscale(${(props) => props.grayscalePercent}%);
`

export const BackgroundPattern = ({
  patternOpacity = 0.2,
  patternScale = 1,
  grayscalePercent = 0,
  ...props
}) => (
  <PatternImageWrapper {...props}>
    <Background
      patternOpacity={patternOpacity}
      patternScale={patternScale}
      grayscalePercent={grayscalePercent}
    />
  </PatternImageWrapper>
)
