import styled from 'styled-components'
import P from '../../typography/P/P'

export const TextButton = styled(P)`
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  margin: 0 auto;
  align-self: center;
  color: ${(props) => props.theme.colors.paragraph};
  :hover {
    text-decoration-line: underline;
    color: ${(props) => props.theme.colors.secondary};
  }
`
