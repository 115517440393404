import { useMediaQuery } from 'react-responsive'
import { breakpoints } from './breakpoints'

export function useMediaIs(size) {
  return useMediaQuery({
    query: `(min-width: ${breakpoints[size]}px)`,
  })
}

export const useMediaIsMobile = () => useMediaIs(breakpoints.xs)
export const useMediaIsTablet = () => useMediaIs(breakpoints.md)
export const useMediaIsDesktop = () => useMediaIs(breakpoints.lg)
export const useMediaIsXL = () => useMediaIs(breakpoints.xl)
