import { useContentAvailableQuery, useUserQuery } from 'api'
import { priceButtonOptions, getButtonStatus } from '../../pages/website/Pricing/PricingButtonsStatus'
import LinkWithReferrer from '../navigation/LinkWithReferrer'
import Button from './Button'

const CtaButton = ({ text, href, block, urlParams }) => (
  <LinkWithReferrer href={href} urlParams={urlParams}>
    <Button hover block={block}>
      {text}
    </Button>
  </LinkWithReferrer>
)

export const CourseButton = ({ slug, block, urlParams }) => {
  const {
    data: { user },
  } = useUserQuery()
  const {
    data: { program, raisingFundsForBusinessProgram },
  } = useContentAvailableQuery()

  return !user ? (
    <CtaButton text="Get Access Now!" href={`/payment/${slug}`} urlParams={urlParams} />
  ) : (
    <CtaButton
      block={block}
      {...priceButtonOptions[slug][getButtonStatus({ user, program, slug, raisingFundsForBusinessProgram })]}
      urlParams={urlParams}
    />
  )
}
