import React from 'react'
import styled from 'styled-components'
import { View } from '@components/layout'
import StaticImage from '@components/images/StaticImg'
import { P, H3 } from '@components/typography'
import { shadows } from '@theme/styles'

const PHOTO_WIDTH = 100

const Wrapper = styled(View)`
  height: 490px;
  width: 100%;
  align-items: center;
  flex: 1;
  margin-bottom: ${(props) => props.theme.spacing.md}px;
`

const Container = styled(View)`
  margin-top: 10px;
  border-radius: 10px;
  width: 300px;
  padding: ${(props) => props.theme.spacing.md}px;
  flex: 1;
  height: 100%;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors.grayLight};

  :hover {
    cursor: pointer;
  }
`

const QuoteText = styled(View)`
  height: 100%;
  overflow: hidden;
  justify-content: space-between;
`

const Details = styled(View)`
  justify-content: center;
`

const Photo = styled(StaticImage)`
  height: ${(props) => PHOTO_WIDTH - props.theme.spacing.md}px;
  width: ${(props) => PHOTO_WIDTH - props.theme.spacing.md}px;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.colors.offWhite};
  margin-right: ${(props) => props.theme.spacing.sm}px;
  /* ${shadows.subtle} */
`

const Name = styled(H3)`
  text-align: left;
  font-weight: bold;
`

const Location = styled(P)`
  font-size: ${(props) => props.theme.fontSizes.sm}px;
  color: ${(props) => props.theme.colors.grayDark};
  margin: 0;
  text-align: left;
`

const Paragraph = styled(P)`
  text-align: left;
  line-height: ${(props) => props.theme.fontSizes.md * 1.2}px;
  font-size: ${(props) => props.theme.fontSizes.sm}px;
`

const BottomRow = styled(View)`
  flex-direction: row;
  align-items: center;
`

export const TestimonialDetailsVariantB = ({ location, name, photo, quote }) => {
  const paragraphs = quote.split('\n')
  return (
    <Wrapper>
      <Container>
        <QuoteText>
          <View>
            {paragraphs.map((para, index) => (
              <Paragraph key={quote.slice(0, 10) + index}>
                {index === 0 && <b>"</b>}
                {para}
                {index === paragraphs.length - 1 && <b>"</b>}
              </Paragraph>
            ))}
          </View>
          <BottomRow>
            <Photo src={`/home/testimonials/${photo}`} />
            <Details>
              <Name>{name}</Name>
              <Location>{location}</Location>
            </Details>
          </BottomRow>
        </QuoteText>
      </Container>
    </Wrapper>
  )
}
