import React from 'react'

const CommonHead = ({ description = '' }) => (
  <>
    <meta key="viewport" name="viewport" content="initial-scale=1.0, width=device-width" />
    <meta property="description" content={description} />
  </>
)

export default CommonHead
