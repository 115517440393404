import {
  ACCELERATOR,
  FOUNDATION,
  STARTER,
  SUBSCRIPTION,
  STARTER_UPGRADE_FOUNDATION,
  STARTER_UPGRADE_ACCELERATOR,
  FOUNDATION_UPGRADE,
  RAISING_FUNDS_FOR_BUSINESS,
} from '@containers/PurchaseApi/PurchaseContainer'

export const getButtonStatus = ({ user, program, slug, raisingFundsForBusinessProgram }) => {
  if (!user) return 'signUp'

  const programs = [
    STARTER,
    FOUNDATION,
    ACCELERATOR,
    SUBSCRIPTION,
    STARTER_UPGRADE_FOUNDATION,
    STARTER_UPGRADE_ACCELERATOR,
    FOUNDATION_UPGRADE,
  ]

  switch (slug) {
    case STARTER: {
      if (programs.includes(program)) return 'goToAccount'
      else return 'buyStarter'
    }
    case FOUNDATION: {
      if (program === STARTER) return 'upgradeToFoundation'
      if (programs.filter((program) => program !== STARTER).includes(program)) return 'goToAccount'
      else return 'buyFoundation'
    }
    case ACCELERATOR: {
      if (program === STARTER) return 'starterUpgradeToAccelerator'
      if (program === FOUNDATION || program === STARTER_UPGRADE_FOUNDATION) return 'foundationUpgradeToAccelerator'
      if (program === ACCELERATOR || program === STARTER_UPGRADE_ACCELERATOR || program === FOUNDATION_UPGRADE)
        return 'goToAccount'
      else return 'buyAccelerator'
    }
    case SUBSCRIPTION: {
      if (program === SUBSCRIPTION) return 'goToAccount'
      else return 'buySubscription'
    }
    case RAISING_FUNDS_FOR_BUSINESS: {
      if (program === RAISING_FUNDS_FOR_BUSINESS || raisingFundsForBusinessProgram !== null) return 'goToAccount'
      else return 'buyRaisingFundsForBusinessProgram'
    }
    default:
      return 'goToAccount'
  }
}

const buyText = 'Get Access Now!'

export const priceButtonOptions = {
  introduction: {
    signUp: { text: 'Get Started for FREE', href: '/register' },
    goToAccount: { text: 'Go to Account', href: '/account' },
  },
  starterProgram: {
    signUp: { text: buyText, href: '/payment/starterProgram' },
    buyStarter: { text: buyText, href: '/payment/starterProgram' },
    goToAccount: { text: 'Go to Account', href: '/account' },
  },
  foundationProgram: {
    signUp: { text: buyText, href: '/payment/foundationProgram' },
    buyFoundation: { text: buyText, href: '/payment/foundationProgram' },
    upgradeToFoundation: { text: 'Upgrade Now', href: `/payment/${STARTER_UPGRADE_FOUNDATION}` },
    goToAccount: { text: 'Go to Account', href: '/account' },
  },
  acceleratorProgram: {
    signUp: { text: buyText, href: '/payment/acceleratorProgram' },
    buyAccelerator: { text: buyText, href: '/payment/acceleratorProgram' },
    starterUpgradeToAccelerator: {
      text: 'Upgrade Now',
      href: `/payment/${STARTER_UPGRADE_ACCELERATOR}`,
    },
    foundationUpgradeToAccelerator: { text: 'Upgrade Now', href: `/payment/${FOUNDATION_UPGRADE}` },
    goToAccount: { text: 'Go to Account', href: '/account' },
  },
  subscriptionProgram: {
    signUp: { text: buyText, href: '/payment/subscriptionProgram' },
    buySubscription: { text: buyText, href: '/payment/subscriptionProgram' },
    goToAccount: { text: 'Go to Account', href: '/account' },
  },
  raisingFundsForBusinessProgram: {
    signUp: { text: buyText, href: '/payment/raisingFundsForBusinessProgram' },
    buyRaisingFundsForBusinessProgram: {
      text: buyText,
      href: '/payment/raisingFundsForBusinessProgram',
    },
    goToAccount: { text: 'Go to Account', href: '/account' },
  },
}
