export const ellipsis = (length, str) => {
  if (!str) {
    return ''
  }
  if (str.length <= length) {
    return str
  }
  const maxStr = str.substr(0, length + 1)
  return maxStr.substring(0, maxStr.lastIndexOf(' ')) + '…'
}
