import { useCallback, useEffect, useState } from 'react'
import throttle from 'lodash/fp/throttle'

const throttleCallback = throttle(100)

export const usePositionInBrowser = (elementId) => {
  const [position, setPosition] = useState({
    /* How much of the element is above the bottom of the viewport */
    percentAboveBottom: 0,
    /* How much of the element is below the top of the viewport */
    percentBelowTop: 0,
    /* How much of the element is visible in the viewport */
    percentOnScreen: 0,
    percentToCenter: 0,
    pxOffsetFromCenter: 0,
    aboveCenter: false,
    /* What percentage of the screen is taken up by this component */
    percentOfScreen: 0,
  })

  const handleScroll = useCallback(() => {
    const el = document.getElementById(elementId)
    if (!el) return

    const rect = el.getBoundingClientRect()

    const topToViewPortBottom = window.innerHeight - rect.top
    const percentAboveBottom = Math.min(1, topToViewPortBottom / rect.height)

    const pxBelowTop = Math.max(0, rect.height + rect.top)
    const pctBelowTop = Math.min(1, pxBelowTop / rect.height)

    const halfScreen = window.innerHeight / 2
    const elCenter = (rect.bottom + rect.top) / 2
    const pxOffsetFromCenter = halfScreen - elCenter
    const pctOnScreen = Math.min(percentAboveBottom, pctBelowTop)

    setPosition({
      topToViewPortBottom,
      percentAboveBottom: percentAboveBottom,
      percentOnScreen: pctOnScreen,
      percentToCenter: 1 - Math.abs(pxOffsetFromCenter) / halfScreen,
      aboveCenter: pxOffsetFromCenter > 0,
      percentOfScreen:
        Math.min(1, Math.max(0, (rect.height * pctOnScreen) / window.innerHeight)) || 0,
    })
  }, [elementId])

  useEffect(() => {
    const throttledScrollHandler = throttleCallback(handleScroll)

    window.addEventListener('scroll', throttledScrollHandler)
    throttledScrollHandler()
    return () => window.removeEventListener('scroll', throttledScrollHandler)
  }, [handleScroll])

  return position
}
