import React from 'react'
import styled, { useTheme } from 'styled-components'
import { Icon } from '@components/images'
import { View } from '@components/layout'
import StaticImage from '@components/images/StaticImg'
import { TextButton } from '@components/input/TextButton/TextButton'
import { P, H3 } from '@components/typography'
import { shadows } from '@theme/styles'
import { ellipsis } from '@utils/text'

const PHOTO_WIDTH = 150

const Wrapper = styled(View)`
  height: ${(props) => (props.expanded ? '720' : '620')}px;
  width: 100%;
  align-items: center;
  flex: 1;
  margin-bottom: ${(props) => props.theme.spacing.md}px;
`

const Container = styled(View)`
  margin-top: 10px;
  border-radius: 10px;
  width: 300px;
  padding: ${(props) => props.theme.spacing.md}px;
  flex: 1;
  height: 100%;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors.grayLight};

  :hover {
    cursor: pointer;
  }
`

const QuoteText = styled(View)`
  height: 100%;
  overflow: hidden;
  justify-content: space-between;
`

const Photo = styled(StaticImage)`
  height: ${(props) => PHOTO_WIDTH - props.theme.spacing.md}px;
  width: ${(props) => PHOTO_WIDTH - props.theme.spacing.md}px;
  border-radius: 50%;
  margin-bottom: ${(props) => props.theme.spacing.md * 1.3}px;
  border: 3px solid ${(props) => props.theme.colors.offWhite};
  ${shadows.subtle}
`

const Details = styled(View)`
  align-items: center;
`

const Name = styled(H3)`
  font-weight: bold;
`

const Location = styled(P)`
  font-size: ${(props) => props.theme.fontSizes.sm}px;
  color: ${(props) => props.theme.colors.grayDark};
`

const Paragraph = styled(P)`
  display: flex;
  text-align: left;
`

const BottomRow = styled(View)`
  position: relative;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
`

const ExpandButtonWrapper = styled(View)`
  margin-top: -${(props) => props.theme.spacing.sm}px;
  align-items: flex-start;
  align-self: flex-start;
`

const CHAR_LIMIT = 350

export const TestimonialDetailsVariantA = ({
  expanded,
  location,
  name,
  photo,
  quote,
  toggleExpanded,
}) => {
  const theme = useTheme()
  const clippedQuote = expanded ? quote : ellipsis(CHAR_LIMIT, quote)
  const isOverLimit = quote.length > CHAR_LIMIT

  return (
    <Wrapper expanded={expanded}>
      <Container>
        <Photo src={`/home/testimonials/${photo}`} />
        <QuoteText>
          <View>
            <Details>
              <Name>{name}</Name>
              <Location>{location}</Location>
            </Details>

            {clippedQuote.split('\n').map((para, index) => (
              <Paragraph key={quote.slice(0, 10) + index}>{para}</Paragraph>
            ))}
            <ExpandButtonWrapper>
              {(isOverLimit || expanded) && (
                <TextButton onClick={toggleExpanded}>
                  {expanded ? 'Read less' : 'Read more'}
                </TextButton>
              )}
            </ExpandButtonWrapper>
          </View>
          <BottomRow>
            <Icon name="quote-right" size={50} color={theme.colors.grayLight} />
          </BottomRow>
        </QuoteText>
      </Container>
    </Wrapper>
  )
}
